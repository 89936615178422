/* eslint-disable max-classes-per-file */

/// <reference lib="webworker"/>

'use strict';

/**
 * From: https://www.w3.org/TR/service-workers/:
 * "The user agents are encouraged to show a warning that the event listeners must be added on the very first evaluation
 * of the worker script."
 * Since async calls are not considered first evaluation of the script, and workbox routing strategies use fetch and
 * message event handlers, routing strategy registration cannot be modularized using AMD, because AMD is asynchronous
 * in nature (and using synchronous API only works if the module has been loaded already).
 * Once we have ES Modules support, this IIFE can be split up to 3 classes
 * https://jira.oraclecorp.com/jira/browse/VBS-8594
 */
(function init() {
  // workbox needs to be loaded before init.js, otherwise workbox API calls won't work
  // vbInitConfig with PWA_CONFIG defined needs to be available on the global context
  if (!(globalThis.workbox && globalThis.vbInitConfig && globalThis.vbInitConfig.PWA_CONFIG)) {
    return;
  }

  globalThis.vbGlobal = globalThis.vbGlobal || {};

  /* eslint-disable */
  // start injected shared scripts (src/template/calculateApplicationUrl.js, src/template/getBaseUrlFromConfig.js)
  /**
   * Based on the location and vbInitConfig parameters, calculates the application URL. The underlying assumption
   * here is that the worker (for example, sw.js) is registered at the same location as index.html.
   * Depending on the existence of the marker 'vp' in the URL, the application URL can take two values:
   *   1) If the marker is not in the URL, the application URL is the current path.
   *   2) Otherwise the application URL is the part of the URL before the marker.
   *
   * This URL can be different from the base URL used to retrieve the application resource.
   * This is will be used by the router sync navigation state and URL and service worker to cache app resources
   * Note: Do not add any references to window to this function.
   * @param {*} loc Window.location or WorkerGlobalScope.location
   * @param {*} vbInitConfig application configuration
   * @param {*} urlMarker (optional) the urlMarker is defined here and the Configuration class is used to access it
   */
  const calculateApplicationUrl = (loc, vbInitConfig = {}, urlMarker = 'vp') => {
    const appName = vbInitConfig.APP_NAME;

    if (appName) {
      // Match the first occurrence of /appName/ or ending with /appName
      const regex = new RegExp(`/${appName}$|/${appName}/`, 'g');
      const result = regex.exec(loc.pathname);

      if (result) {
        let appUrl = loc.origin + loc.pathname.substring(0, result.index + 1 + appName.length);
        // Make sure to terminate with a '/'
        if (appUrl.slice(-1) !== '/') {
          appUrl += '/';
        }

        return appUrl;
      }
    }
    // Retrieve the URL without search or hash. Depending on context, pathname can be:
    // - /ic/builder/rt/rx/1.0/webApps/abc/ on the main thread
    // - /ic/builder/rt/rx/1.0/webApps/abc/sw.js in the service worker context
    // - /context.html or /debug.html for unit test
    let appUrl = loc.origin + loc.pathname;
    const visualIndex = appUrl.indexOf(`/${urlMarker}/`);

    // Make sure that we either have .../vp/... or it ends with /vp
    if (visualIndex > 0) {
      appUrl = appUrl.substring(0, visualIndex + 1);
    } else if (appUrl.substring(appUrl.length - urlMarker.length - 1) === `/${urlMarker}`) {
      appUrl = appUrl.substring(0, appUrl.length - urlMarker.length - 1);
    } else {
      // Location path might end with a resource
      // eslint-disable-next-line no-restricted-syntax
      for (const r of ['index.html', 'sw.js', 'debug.html', 'context.html']) {
        const resourceIndex = appUrl.indexOf(r);
        if (resourceIndex > 0) {
          appUrl = appUrl.substring(0, resourceIndex);
          break;
        }
      }
    }

    // Make sure to terminate with a '/'
    if (!appUrl.endsWith('/')) {
      appUrl += '/';
    }
    return appUrl;
  };
  /**
   * The base URL is where all the application resources are loaded from.
   * Note: Do not add any references to window to this function.
   * @param {*} loc Window.location or WorkerGlobalScope.location
   * @param {*} vbInitConfig application configuration
   * @param {*} applicationUrl calculated application URL
   * @return {String}  the baseUrl
   */
  const getBaseUrlFromConfig = (loc, vbInitConfig, applicationUrl) => {
    // For mobile, the base URL can only be relative to application URL because
    // application resource are always stored locally
    if (vbInitConfig.IS_MOBILE) {
      return applicationUrl;
    }
    // If BASE_URL is defined in vbInitConfig, we use it as-is.
    let baseUrl = vbInitConfig.BASE_URL;
    // If not defined, the base URL is a combination of application URL and BASE_URL_TOKEN
    if (!baseUrl) {
      baseUrl = applicationUrl;
      const baseUrlToken = vbInitConfig.BASE_URL_TOKEN;
      if (baseUrlToken) {
        // When using vanity URL, BASE_URL_TOKEN starts with '/', which is relative to the domain.
        if (baseUrlToken[0] === '/') {
          // Note: prepend the origin due to existing apps relying on the full absolute url
          baseUrl = `${loc.origin}${baseUrlToken}`;
        } else {
          baseUrl = `${baseUrl}${baseUrlToken}`;
        }
      }
    }
    // Make sure to terminate with a '/', because BASE_URL or BASE_URL_TOKEN might not be
    if (!baseUrl.endsWith('/')) {
      baseUrl += '/';
    }
    return baseUrl;
  };

  // end injected shared scripts
  /* eslint-enable */

  // for unit testing access:
  globalThis.vbGlobal.calculateApplicationUrl = calculateApplicationUrl;
  globalThis.vbGlobal.getBaseUrlFromConfig = getBaseUrlFromConfig;
  globalThis.vbGlobal.PathConfigurationClass = class PathConfiguration {
    /**
     * @param {*} config similar to vbInitConfig, with paths fixed
     */
    constructor(config = globalThis.vbInitConfig) {
      this.config = config;
      // since value of applicationUrl depends on location context, this is not a pure class
      this.applicationUrl = calculateApplicationUrl(globalThis.location, this.config);
      this.baseUrl = getBaseUrlFromConfig(globalThis.location, this.config, this.applicationUrl);
    }

    // caches result of calculateApplicationUrl
    getAppPath() {
      return this.applicationUrl;
    }

    // caches result of getBaseUrlFromConfig
    getBaseUrlPath() {
      return this.baseUrl;
    }
  };

  /**
   * Manages different configuration options for the service worker.
   */
  globalThis.vbGlobal.ServiceWorkerConfigurationClass = class ServiceWorkerConfiguration {
    /**
     * @param {*} config similar to vbInitConfig, with paths fixed
     */
    constructor(config = globalThis.vbInitConfig) {
      this.config = config;
      this.pathConfig = new globalThis.vbGlobal.PathConfigurationClass(config);
      // assume configuration does not change in a given global context, so this is faking singleton pattern
      globalThis.vbGlobal.Config = this;
    }

    /**
     * @returns {boolean} true, if caching (precaching and opt in dynamic caching) should be enabled for the application
     * Caching is enabled unless vbInitConfig.PWA_CONFIG.disableCaching is set to true.
     * In all other cases, it is enabled.
     */
    isCachingEnabled() {
      return !(this.config.PWA_CONFIG && this.config.PWA_CONFIG.disableCaching === true);
    }

    /**
     *
     * @param {string[]} precacheManifest the precache manifest to check
     * @returns {boolean} true, if caching is enabled and precache manifest is not empty
     */
    isPrecachingEnabled(precacheManifest) {
      return !!(this.isCachingEnabled() && precacheManifest && precacheManifest.length > 0);
    }

    /**
     *
     * @returns {boolean} true, if dynamic caching (vb, jet, etc) should be enabled. False by default in 21.10,
     * this is an opt in for the application
     */
    isDynamicCachingEnabled() {
      return !!(this.isCachingEnabled()
        && this.config.PWA_CONFIG
        && (this.config.PWA_CONFIG.enableDynamicCaching === true));
    }

    /**
     * @returns {boolean} true, if support for offline routing of router strategy path requests should be enabled.
     * Returns false by default - see blocking issue: https://jira.oraclecorp.com/jira/browse/VBS-14526
     */
    isRouterStrategyPathEnabled() {
      return !!(this.config.PWA_CONFIG
        && (this.config.PWA_CONFIG.enableRouterStrategyPath === true));
    }

    /**
     * @returns {boolean} true, if navigation preload is enabled in PWA_CONFIG
     */
    isNavigationPreloadEnabled() {
      return !!(this.config.PWA_CONFIG && (this.config.PWA_CONFIG.enableNavigationPreload === true));
    }

    /**
     * Offline page is calculated at RT relative to base url for the app. No processing on offlinePage parameter is
     * performed during grunt build.
     *
     * For example:
     * https://www.example.com/ic/builder/rt/rxx/1.0/webApps/abc/version_1623951103568/offlinePage.html
     * or
     * https://dev.oraclevb.com/apps/redwood/2201.0.0-alpha.1.5/offlinePage.html
     *
     * @returns {string} url for the offline page based on PWA_CONFIG.offlinePage parameter in vbInitConfig.
     */
    getOfflinePageUrl() {
      const offlinePage = this.config.PWA_CONFIG.offlinePage;
      if (offlinePage) {
        return `${this.pathConfig.getBaseUrlPath()}${offlinePage}`;
      }
      return undefined;
    }
  };
  globalThis.vbGlobal.DynamicCacheManagerClass = class DynamicCacheManager {
    constructor(config = globalThis.vbInitConfig, workbox = globalThis.workbox) {
      this.config = config;
      // cache prefix specified in PWA_CONFIG takes precedence
      this.prefix = (config.PWA_CONFIG && config.PWA_CONFIG.cachePrefix) ? config.PWA_CONFIG.cachePrefix : 'wb-';
      this.workbox = workbox;
      this.swConfig = new globalThis.vbGlobal.ServiceWorkerConfigurationClass(config);
      this.pathConfig = this.swConfig.pathConfig;
      this.logger = globalThis.vbGlobal.logger || console;
      this.jetCacheName = `${this.prefix}jet-${this.config.jetPath}`;
      this.vbCacheName = `${this.prefix}vb-${this.config.vbPath}`;
      this.appCacheName = `${this.prefix}app-${this.pathConfig.getAppPath()}`;
      this.baseUrlCacheName = `${this.prefix}base-${this.pathConfig.getBaseUrlPath()}`;
      this.baseUrlImagesCacheName = `${this.prefix}base-img-${this.pathConfig.getBaseUrlPath()}`;
      this.navigateCacheName = `${this.prefix}navigate-${this.pathConfig.getBaseUrlPath()}`;
      this.workboxCacheName = `${this.prefix}workbox-${this.config.workboxCdn}`;
      this.isNavigationRouteRegistered = false;
      this.workboxInitialized = false;
      // following might be set in initializeWorkbox()
      this.precacheName = undefined;
      this.expirationPlugin = undefined;
      this.corsPlugin = undefined;
      this.ignoreSearchPlugin = undefined;
      this.catchHandler = undefined;
    }

    // TODO: the RegExp should be calculated based on current cache names and prefix, but that would require cache
    // name to have unique and common part. We can address this once offline support is added. So, for example,
    // for jet cache:
    // 'wb-jet-' would be a common part, and
    // 'https://static.oracle.com/cdn/jet/9.2.0/' would be unique path
    /**
     * @returns {RegExp} a regular expression matching names of all VB managed caches (no precache)
     * disregarding script versions they cache
     */
    getCurrentCachePrefixesRegExp() {
      const r = ['jet', 'vb', 'app', 'base', 'base-img', 'navigate', 'workbox']
        .map((n) => `${this.prefix}${n}-`)
        .join('|');
      return new RegExp(r);
    }

    async initializeWorkbox() {
      // workbox config must be set before accessing workbox.* modules
      this.workbox.setConfig({
        // workbox is automatically set to debug mode when running on localhost
        debug: this.config.DEBUG,
        // modulePrefix has to be set, otherwise workbox modules will be loaded from
        // https://storage.googleapis.com/workbox-cdn/releases/XXX
        // https://developers.google.com/web/tools/workbox/modules/workbox-sw#using_local_workbox_files_instead_of_cdn
        modulePathPrefix: this.config.workboxCdn,
      });

      // Although it should be possible to import other workbox scripts on demand, it does not always work
      // Workbox documents restrictions on imports from async code:
      // https://developers.google.com/web/tools/workbox/modules/workbox-sw#avoid_async_imports
      // but I've run into issues with sync code as well. See:
      // https://jira.oraclecorp.com/jira/browse/VBS-6856 and
      // https://jira.oraclecorp.com/jira/browse/VBS-10367
      //
      // In addition, workbox config must be set before accessing workbox.* modules
      this.workbox.loadModule('workbox-core');
      this.workbox.loadModule('workbox-precaching');

      // set cache prefix/precache controller regardless of whether caching is enabled to allow active sw
      // to delete stale caches
      this.workbox.core.setCacheNameDetails({
        prefix: this.prefix,
      });
      // set up precache name. This must happen regardless of whether caching is enabled or not, because when caching
      // is disabled, we might have to delete old precache, and deletion process is based on cache name
      this.precacheName = this.workbox.core.cacheNames.precache;
      if (this.swConfig.isCachingEnabled()) {
        this.expirationPlugin = new this.workbox.expiration.ExpirationPlugin({
          maxEntries: 60, // Keep at most 60 entries.
          maxAgeSeconds: 30 * 24 * 60 * 60, // Don't keep any entries for more than 30 days.
          purgeOnQuotaError: true, // Automatically cleanup if quota is exceeded.
        });
        // https://developers.google.com/web/tools/workbox/guides/using-plugins
        // eslint-disable-next-line max-len
        // https://stackoverflow.com/questions/46281732/how-to-ignore-url-querystring-from-cached-urls-when-using-workbox
        this.ignoreSearchPlugin = {
          cachedResponseWillBeUsed: async ({ request, cachedResponse }) => {
            if (cachedResponse) {
              return cachedResponse;
            }
            return caches.match(request.url, { ignoreSearch: true });
          },
        };

        this.corsPlugin = {
          requestWillFetch: async ({ request }) => {
            if (request.mode === 'cors') {
              return request;
            }
            this.logger.info('Service worker corsPlugin removing no-cors for: ', request.url);
            const corsRequest = new Request(request.url, { mode: 'cors' });
            return corsRequest;
          },
        };

        this.allowMissingPrecacheEntriesPlugin = {
          cacheWillUpdate: async (params) => {
            // VBS-26262 : Populating cache in serviceworker is too fragile
            // Mimic PrecacheStrategy's defaultPrecacheCacheabilityPlugin, EXCEPT allow missing resources (404)
            if (params.response && (params.response.status === 404)) {
              this.logger.warn('Allowing missing resource in precache:', params.response.url, 'status:', params.response.status);
              return params.response;
            }
            return this.workbox.precaching.PrecacheStrategy.defaultPrecacheCacheabilityPlugin.cacheWillUpdate(params)
          },
        };

        this.catchHandler = async ({ event }) => {
          const offlinePage = this.swConfig.getOfflinePageUrl();
          switch (event.request.destination) {
            case 'document':
              if (offlinePage) {
                let offlinePageFromPrecache;
                if (this.precacheController) {
                  offlinePageFromPrecache = await this.precacheController.matchPrecache(offlinePage);
                  if (offlinePageFromPrecache) {
                    return offlinePageFromPrecache;
                  }
                  this.logger.error(`Offline page: ${offlinePage} was not found in precache`);
                }
                return Response.error();
              }
              break;
            default:
              // If we don't have a fallback, just return an error response.
              return Response.error();
          }
          return Response.error();
        };
      }
      this.workboxInitialized = true;
    }

    /**
     *
     * @param {Object} options
     * @param {string} options.offlinePageRevision revision info (or hash) used for caching offline page
     * @param {string[]} options.precacheManifest a list of precache entries injected during the build
     */
    initializePrecache({ offlinePageRevision = null, precacheManifest = [] }) {
      if (!this.workboxInitialized) {
        this.logger.error('initializeWorkbox() must be called before initializePrecache()');
        return;
      }
      if (this.swConfig.isCachingEnabled()) {
        this.precacheController = new this.workbox.precaching.PrecacheController({ plugins: [ this.allowMissingPrecacheEntriesPlugin ] });
        const externalManifestLocation = this.config.PWA_CONFIG.precacheManifest;
        if (externalManifestLocation) {
          try {
            // external precache manifest defines precacheManifest in global context:
            // globalThis.precacheManifest = self.__WB_MANIFEST;
            importScripts(externalManifestLocation);
            this.precacheManifest = globalThis.precacheManifest || [];
          } catch (error) {
            this.logger.error(`Importing precache manifest failed with error: ${error}`);
          }
        } else {
          // injectionPoint for workbox-build to inject precache manifest injected during the build
          // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-build#.injectManifest
          // eslint-disable-next-line no-underscore-dangle
          this.precacheManifest = precacheManifest;
        }
        // as a workaround for lack of precache manifest generation in 21.07, if there is offline page specified
        // add it to precache manifest
        // for performance reason it is assumed that if precache manifest is not empty, it contains an offline page
        const offlinePage = this.swConfig.getOfflinePageUrl();
        if (this.precacheManifest.length === 0 && offlinePage) {
          this.precacheManifest.push({ url: offlinePage, revision: offlinePageRevision });
        }
        this.precacheController.addToCacheList(this.precacheManifest);
      }
      // allow workbox to clean up incompatible precache's that were created by older versions of Workbox.
      // eslint-disable-next-line max-len
      // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-precaching#.cleanupOutdatedCaches
      this.workbox.precaching.cleanupOutdatedCaches();
    }

    getApplicationUrlRegExp(applicationUrl, urlMarker = 'vp') {
      const appName = this.config.APP_NAME;
      let appUrlWithMarkerOrAppName;
      if (appName) {
        appUrlWithMarkerOrAppName = applicationUrl;
      } else {
        appUrlWithMarkerOrAppName = `${applicationUrl}${urlMarker}/`;
      }
      return new RegExp(appUrlWithMarkerOrAppName);
    }

    getPrefix() {
      return this.prefix;
    }

    /**
     *
     * @returns {string[]} a list of dynamic cache names used by VB, regardless of whether dynamic caching is actually
     * enabled or not
     */
    getCurrentCacheNames() {
      const cacheNames = [
        this.jetCacheName,
        this.vbCacheName,
        this.baseUrlCacheName,
        this.baseUrlImagesCacheName,
        this.navigateCacheName,
        this.workboxCacheName,
        // globalThis.workbox.core.cacheNames.precache is not included because its lifecycle is managed by workbox
      ];
      // a separate cache for app resources is only needed if there is BASE_URL_TOKEN or BASE_URL specified
      // because, in both cases the location of index.html and the location of page model are different
      if (this.config.BASE_URL_TOKEN || this.config.BASE_URL) {
        cacheNames.push(this.appCacheName);
      }
      return cacheNames;
    }

    registerNavigationRoute() {
      this.logger.info('Registering route for navigation requests');
      // Only when dynamic caching is enabled, NetworkFirst strategy is used. Otherwise, NetworkOnly strategy is used
      // and the route is registered for the sole purpose of registering catch handler for offline page support
      let strategy;
      const networkStrategyOptions = {
        cacheName: this.navigateCacheName,
        plugins: [this.ignoreSearchPlugin],
      };
      if (this.swConfig.isDynamicCachingEnabled()) {
        strategy = new this.workbox.strategies.NetworkFirst(networkStrategyOptions);
      } else {
        strategy = new this.workbox.strategies.NetworkOnly(networkStrategyOptions);
      }
      // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-routing#~handlerCallback
      const navigationHandler = async (context) => {
        try {
          return await strategy.handle(context);
        } catch (error) {
          // navigation request can be either in precache or navigation cache
          const match = await caches.match(context.url);
          if (match) {
            return match;
          }
          // handler needs to throw an error in order for control to be forwarded to catch handler
          throw Response.error();
        }
      };
      // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-routing.NavigationRoute
      const navigationRoute = new this.workbox.routing.NavigationRoute(navigationHandler);
      navigationRoute.setCatchHandler(this.catchHandler);
      this.workbox.routing.registerRoute(navigationRoute);
      this.isNavigationRouteRegistered = true;
    }

    registerJetRoute() {
      // routing strategy for JET resources
      // JET requests that are result of calling requirejs are issued in no-cors mode, and therefore end up
      // as opaque responses in cache. Since opaque responses result in cache bloat, as browsers cannot
      // accurately determine response size (see Chrome 7MB limit
      // https://developers.google.com/web/tools/chrome-devtools/progressive-web-apps#opaque-responses
      // and https://cloudfour.com/thinks/when-7-kb-equals-7-mb/),
      // and JET CDN supports CORS, we need to issue cors mode requests for JET resources.
      const jetRoute = `${this.config.jetPath}(.*)`;
      this.logger.info('Registering route: ', jetRoute, ' for JET');
      this.workbox.routing.registerRoute(
        new RegExp(jetRoute),
        new this.workbox.strategies.CacheFirst({
          cacheName: this.jetCacheName,
          plugins: [this.corsPlugin],
        }),
      );
    }

    registerVbRoute() {
      // routing strategy for VB sources
      const vbRoute = `${this.config.vbPath}(.*)`;
      this.logger.info('Registering route: ', vbRoute, ' for VB');
      this.workbox.routing.registerRoute(
        new RegExp(vbRoute),
        new this.workbox.strategies.CacheFirst({
          cacheName: this.vbCacheName,
          plugins: [this.ignoreSearchPlugin],
        }),
      );
    }

    registerBaseImageRoute() {
      const baseRoute = `${this.pathConfig.getBaseUrlPath()}(.*)`;
      const baseRegExp = new RegExp(baseRoute);
      // routing strategy for base app images, with expiration after one month
      this.logger.info('Registering route: ', baseRoute, ' for application images');
      const matchBaseAppImages = ({ url, request }) => (baseRegExp.test(url) && request.destination === 'image');
      this.workbox.routing.registerRoute(
        matchBaseAppImages,
        new this.workbox.strategies.CacheFirst({
          cacheName: this.baseUrlImagesCacheName,
          plugins: [
            new this.workbox.cacheableResponse.CacheableResponsePlugin({
              statuses: [0, 200],
            }),
            this.expirationPlugin,
          ],
        }),
      );
    }

    registerBaseRoute() {
      // routing strategy for base app resources
      const baseRoute = `${this.pathConfig.getBaseUrlPath()}(.*)`;
      const baseRegExp = new RegExp(baseRoute);
      this.logger.info('Registering route: ', baseRoute, ' for application resources');
      this.workbox.routing.registerRoute(
        baseRegExp,
        new this.workbox.strategies.CacheFirst({
          cacheName: this.baseUrlCacheName,
          plugins: [this.ignoreSearchPlugin],
        }),
      );
    }

    registerIndexHtmlPrecacheRoute() {
      const appRoute = this.pathConfig.getAppPath();
      const indexHtml = `${appRoute}index.html`;

      // If 'index.html' is not in the precache, just skip this registration.
      if (!this.precacheController.getCacheKeyForURL(indexHtml)) {
        return;
      }

      // Network-First Precache Strategy
      // This is because login injects user information into index.html, so after logout
      // we want to get a new index.html without user information, and the reverse for login.
      // See VBS-19296
      const networkFirstPrecacheStrategy = new this.workbox.strategies.NetworkFirst({
        cacheName: this.precacheName, // Retrieve from the precache
        plugins: [this.ignoreSearchPlugin],
      });

      //
      // Network-First routing strategy for 'index.html', so we always check for a newer one first.
      //
      this.logger.info('Registering route: ', indexHtml, ' for index.html');
      this.workbox.routing.registerRoute(
        new RegExp(`${appRoute}index\\.html`),
        networkFirstPrecacheStrategy,
      );

      //
      // Network-First routing strategy for '/'.
      //
      this.logger.info('Registering route: ', appRoute, ' for index.html');

      // RouteHandlerCallback that redirects to 'index.html' on failure.  This can happen if '/' hasn't been put into the
      // precache yet by the PrecacheRoute match algorithm
      // 1) checks network, then precache (via the NetworkFirst strategy)
      // 2) then checks precache for 'index.html'
      // 3) then fails
      const directoryIndexHandler = async (options) => {
        try {
          return await networkFirstPrecacheStrategy.handle(options);
        } catch (error) {
          const match = await this.precacheController.matchPrecache(indexHtml);
          if (match) {
            return match;
          }
          // handler needs to throw an error in order for control to be forwarded to catch handler
          throw Response.error();
        }
      };

      this.workbox.routing.registerRoute(
        new RegExp(`${appRoute}$`),
        directoryIndexHandler,
      );
    }

    registerAppRoute() {
      if (this.config.BASE_URL_TOKEN || this.config.BASE_URL) {
        // routing strategy for application resources (directly under context root). For now this also handles
        // index.html request - it can be changed to (.+) once we have precaching enabled
        const appRoute = `${this.pathConfig.getAppPath()}(.*)`;
        this.logger.info('Registering route: ', appRoute, ' for app resources');
        this.workbox.routing.registerRoute(
          new RegExp(appRoute),
          new this.workbox.strategies.NetworkFirst({
            cacheName: this.appCacheName,
            plugins: [this.ignoreSearchPlugin],
          }),
        );
      }
    }

    registerWbRoute() {
      // routing strategy for workbox scripts
      const workboxRoute = `${this.config.workboxCdn}(.*)`;
      this.logger.info('Registering route: ', workboxRoute, ' for workbox');
      this.workbox.routing.registerRoute(
        new RegExp(workboxRoute),
        new this.workbox.strategies.CacheFirst({
          cacheName: this.workboxCacheName,
        }),
      );
    }

    /**
    * A PrecacheStrategy callback to generate url variations.
    * Should match server logic that processes logical URL's to support path router strategy.
    * At the simplest, given a navigation request for logical url:
    * https://masterdev-vboci.integration.test.ocp.oc-test.com/ic/builder/rt/rxx/1.0/webApps/abc/vp/shell/css
    * that represents a sub flow request, VB needs to return content of index.html located at
    * https://masterdev-vboci.integration.test.ocp.oc-test.com/ic/builder/rt/rxx/1.0/webApps/abc/index.html
    *
    * For resource, requests, such as:
    * https://masterdev-vboci.com/ic/builder/rt/rxx/1.0/webApps/abc/vp/shell/manifest.webmanifest
    * VB needs to return
    *  https://masterdev-vboci.com/ic/builder/rt/rxx/1.0/webApps/abc/manifest.webmanifest
    *
    * @see https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-precaching
    * @param {Object} options
    * @param {string} options.url url of a request to manipulate
    * @param {string} options.applicationUrl
    * @param {RegExp} options.applicationUrlRegExp RegEx to match app url
    * @param {RegExp} options.resourceRegExp RegEx to match last resource on the url
    * @returns {URL[]} a list of manipulated URL's
    */
    // TODO: behavior should match documentation - see https://jira.oraclecorp.com/jira/browse/VBS-14526
    manipulateLogicalUrl({
      url, applicationUrl, applicationUrlRegExp, resourceRegExp,
    }) {
      const isRouterStrategyPathEnabled = this.swConfig.isRouterStrategyPathEnabled();
      if (!isRouterStrategyPathEnabled) {
        return [];
      }
      // only manipulate url's that correspond to this app
      const applicationUrlRegExResult = applicationUrlRegExp.exec(url);
      if (applicationUrlRegExResult) {
        // for resource requests, return applicationUrl + resource name
        const resourceRegExResult = resourceRegExp.exec(url);
        if (resourceRegExResult && resourceRegExResult[2]) {
          return [
            new URL(`${applicationUrl}${resourceRegExResult[2]}`),
          ];
        }
        // all other in scope requests are considered sub flow requests
        return [
          new URL(`${applicationUrl}index.html`),
        ];
      }
      return [];
    }

    registerPrecacheRoute() {
      const applicationUrl = this.pathConfig.getAppPath();
      const applicationUrlRegExp = this.getApplicationUrlRegExp(applicationUrl);
      // somethingWithoutADot/somethingWithoutASlash.somethingWithoutASlash - match at the end and capture in group 2
      // eslint-disable-next-line no-useless-escape
      const resourceRegExp = new RegExp(/([^.]*\/)+(([^\/])*\.[^\/]+)$/);
      const isRouterStrategyPathEnabled = this.swConfig.isRouterStrategyPathEnabled();
      let urlManipulation;

      if (isRouterStrategyPathEnabled) {
        // Treat sub flow requests (for routerStrategy: 'path') as requests for index.html and resolve
        // resource request as relative to application url.
        // It is important that index.html is included in a result, and that index.html is in precache, because
        // PrecacheRoute calls generateURLVariations() only once when performing match, so the end result
        // of custom url manipulation must be a URL that is specified in precache manifest.
        // eslint-disable-next-line max-len
        // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-precaching#~urlManipulation
        urlManipulation = ({ url }) => {
          const result = this.manipulateLogicalUrl({
            url,
            applicationUrl,
            applicationUrlRegExp,
            resourceRegExp,
          });
          if (result && result[0]) {
            this.logger.log('PrecacheRoute url manipulation returning: ', result[0], ' for ', url);
          }
          return result;
        };
      } else {
        urlManipulation = () => [];
      }
      const precacheRoute = new this.workbox.precaching.PrecacheRoute(this.precacheController,
        {
          // ignore all query params when matching precache entries
          ignoreURLParametersMatching: [/.*/],
          urlManipulation,
        });
      this.workbox.routing.registerRoute(precacheRoute);

      this.registerNavigationRoute();
    }

    registerRoutingStrategies() {
      if (this.swConfig.isPrecachingEnabled(this.precacheManifest)) {
        // We want index.html to be Network-First, so registering its strategy before registering the precache
        // https://developer.chrome.com/docs/workbox/modules/workbox-precaching/#serving-precached-responses
        this.registerIndexHtmlPrecacheRoute();

        this.registerPrecacheRoute();
      }

      if (!this.swConfig.isDynamicCachingEnabled()) {
        this.logger.info('Skipping registerRoutingStrategies: dynamic caching is disabled');
        return;
      }

      if (!this.isNavigationRouteRegistered) {
        this.registerNavigationRoute();
      }

      this.registerJetRoute();

      this.registerVbRoute();

      this.registerBaseImageRoute();

      this.registerBaseRoute();

      this.registerAppRoute();

      this.registerWbRoute();
    }
  };
}());

define("generated/sw/private/init", function(){});

